import { useMediaQuery } from "@mui/material";

export const useCustomMediaQueries = () => {
    const isDesktop = useMediaQuery("(min-width: 1200px)");
    const isPadMd = useMediaQuery("(min-width: 960px)");
    const isPad = useMediaQuery("(min-width: 810px)");
    const isMobile = useMediaQuery("(max-width: 809px)");

    
    return { isDesktop, isPadMd, isPad, isMobile };
};