import { Box, buttonClasses, menuItemClasses, Typography } from "@mui/material";
import { NestedDropdown } from 'mui-nested-menu';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import useResize from "../../config/themes/responsive";

export function ClientSourceSelector({ enumItems, sourceFilter, setSourceFilter, sourceTitle, setSourceTitle }) {
  const { t } = useTranslation(["translation", "form"]);
  const { user } = useSelector(x => x.auth);
  const [type, setType] = useState('department');

  const [open, setOpen] = useState(false);
  const scaleFactor = useResize();

  localStorage.setItem('sourceFilter', JSON.stringify(sourceFilter))
  localStorage.setItem('sourceTitle', sourceTitle)

  const handlerClick = (id, title) => {
    // setType(type)
    setSourceTitle(title)
    setSourceFilter({ id })
    setOpen(false)
  }
  const menuItemsData = {
    label: sourceFilter ? (sourceFilter.id ? t(sourceTitle) : t('allSources')) : t('allSources'), items: [],
  };
  const sx = theme => ({
    padding: "5px 10px 5px 2px",
    fontWeight: "500",
    fontSize: "20px",
    fontFamily: "Fixel Medium",
    lineHeight: "24px",
    margin: "0 10px",
    [theme.breakpoints.up("md")]: {
      fontSize: `${20 * scaleFactor / 16}vw`,
      lineHeight: `${24 * scaleFactor / 16}vw`,
      margin: `0 ${10 * scaleFactor / 16}vw`,
      padding: `${5 * scaleFactor / 16}vw ${10 * scaleFactor / 16}vw ${5 * scaleFactor / 16}vw ${2 * scaleFactor / 16}vw`,
      '& svg': {
        width: `${16 * scaleFactor / 16}vw`,
        height: `${16 * scaleFactor / 16}vw`,
      },
      '& p': {
        fontSize: `${16 * scaleFactor / 16}vw`,
        lineHeight: `${24 * scaleFactor / 16}vw`,
        paddingLeft:  `${8 * scaleFactor / 16}vw`,
        paddingRight:  `${8 * scaleFactor / 16}vw`,
      },
    },
    [`&.${menuItemClasses.root}:hover`]: {
      backgroundColor: theme.palette.primary.mainWithOpacity,
      color: theme.palette.primary.main,
    },
  });

  menuItemsData.items.push({
    label: t('allSources'),
    callback: () => {
      handlerClick(null, null)
    },
    sx
  })

  enumItems.forEach(enumItem => {

    if (enumItem.apartments) {

      const newDepartment = {
        label: t(enumItem.name),
        callback: () => handlerClick(enumItem.name, enumItem.name),
        sx,
        rightIcon: <ArrowRightIcon />,
        items: [{
          label: t('chooseDepartment') + " " + t(enumItem.name),
          callback: () => handlerClick(enumItem.name, enumItem.name),
          sx
        }]
      }
  
      enumItem.apartments.forEach(apertment => {
        if (apertment.status === 0) {
          return;
        }

        const newLabel = {
          label: apertment.name,
          callback: () => handlerClick(apertment.name, apertment.name),
          sx
        }
        newDepartment.items.push(newLabel);
      })
  
      menuItemsData.items.push(newDepartment);

    } else {
      menuItemsData.items.push({label: t(enumItem.name), callback: () => {handlerClick(enumItem.name, enumItem.name)}, sx});
    }
  });

  return <Box
    sx={(theme) => ({
      alignItems: "center",
      display: "flex",
      mr: { xs: 6 },
      [theme.breakpoints.up("md")]: {
        mr: `${6 * 8 * scaleFactor / 16}vw`,
      }
    })}
  >
    <Typography variant="text"
                sx={(theme) => ({
                  color: "newGrey.dark", fontSize: "16px", fontWeight: "500", fontFamily: "Fixel Medium", lineHeight: "24px",
                  [theme.breakpoints.up("md")]: {
                    fontSize: `${14 * scaleFactor / 16}vw`,
                    lineHeight: `${16 * scaleFactor / 16}vw`,
                  }
                })}
    >
      {type === "department" ? t('source') : t('source')}:
    </Typography>
    <NestedDropdown
      menuItemsData={menuItemsData}
      MenuProps={{
        elevation: 3,
      }}
      onClick={() => setOpen(true)}
      ButtonProps={{
        endIcon: open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />,
        variant: 'text',
        sx: (theme) => ({
          color: "dark",
          fontWeight: "500",
          fontSize: "16px",
          fontFamily: "Fixel Medium",
          lineHeight: "24px",
          paddingLeft: { xs: "20px"},
          [theme.breakpoints.up("md")]: {
            fontSize: `${14 * scaleFactor / 16}vw`,
            lineHeight: `${24 * scaleFactor / 16}vw`,
            paddingLeft: `${20 * scaleFactor / 16}vw`
          },
          '& span': {
            marginRight: `${-4 * scaleFactor / 16}vw`,
            marginLeft: `${8 * scaleFactor / 16}vw`,
          },
          '& span svg': {
            width: `${20 * scaleFactor / 16}vw`,
            height: `${20 * scaleFactor / 16}vw`,
          },
          [`&.${buttonClasses.root}:hover`]: {
            backgroundColor: "unset",
            color: theme.palette.primary.main,
          },
        }),
      }}
    />
  </Box>
}
