import { Grid } from "@mui/material";
import { apartmentApi } from "helpers";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { AddressAutocomplete } from "./AddressAutocomplete";
import { LabeledAsyncSelect } from "./LabeledAsyncSelect";
import { LabeledInput } from "./LabeledInput";
import { InputPhoneMask } from "./InputPhoneMask"
import { LabeledSelect } from "./LabeledSelect";
import useResize from "../../config/themes/responsive";

export function MainInformationBlock({ disabled, formik }) {
  const scaleFactor = useResize();
  const { t } = useTranslation(["translation", "form"]);

  const [disabledCity, setDisabledCity] = useState(true);
  const [disabledStreet, setDisabledSreet] = useState(true);

  const [disabledCityRegion, setDisabledCityRegion] = useState(true);
  const [disabledCitySubregion, setDisabledCitySubregion] = useState(true);
  const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  useEffect(() => {
    if (formik.values.state) {
      setDisabledCity(false);
    } else {
      setDisabledCity(true);
    }

    if (formik.values.city) {
      setDisabledSreet(false);
    } else {
      setDisabledSreet(true);
    }

    if (formik.values.city?.name === 'Київ') {
      setDisabledCityRegion(false);
      setDisabledCitySubregion(false);
    } else {
      setDisabledCityRegion(true);
      setDisabledCitySubregion(true);
    }

  }, [formik])

  return <Grid className={'000'} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={(theme) => ({
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${24 * scaleFactor / 16}vw)`,
      marginLeft: `${-24 * scaleFactor / 16}vw`,
      marginTop: `${-8 * scaleFactor / 16}vw`,
      '&.MuiGrid-item': {
        paddingLeft: `${24 * scaleFactor / 16}vw`,
        paddingTop: `${8 * scaleFactor / 16}vw`,
      },
      '& h4.MuiTypography-h4': {
        marginTop: `${30 * scaleFactor / 16}vw`,
        fontSize: `${14 * scaleFactor / 16}vw`,
        lineHeight: `${22 * scaleFactor / 16}vw`,
      },
      '& .MuiInputBase-formControl.MuiInputBase-root': {
        //paddingRight: `${65 * scaleFactor / 16}vw !important`,
        padding: `${12 * scaleFactor / 16}vw ${39 * scaleFactor / 16}vw ${12 * scaleFactor / 16}vw ${12 * scaleFactor / 16}vw`,
        fontSize: `${16 * scaleFactor / 16}vw`,
        lineHeight: `${23 * scaleFactor / 16}vw`,
        borderTopLeftRadius: `${4 * scaleFactor / 16}vw`,
        borderTopRightRadius: `${4 * scaleFactor / 16}vw`
      },
      '& .MuiInputBase-root input': {
        padding: `${4 * scaleFactor / 16}vw 0 ${5 * scaleFactor / 16}vw !important`,
        fontSize: `${16 * scaleFactor / 16}vw`,
        lineHeight: `${23 * scaleFactor / 16}vw`,
        minWidth: `${30 * scaleFactor / 16}vw !important`,
        height: `${22 * scaleFactor / 16}vw !important`,
      },
      '& .MuiInputBase-root:after': {
        borderBottom: `${2 * scaleFactor / 16}vw solid #59C8FA`
      },
      '& .MuiInputBase-root .MuiAutocomplete-endAdornment': {
        right: `${9 * scaleFactor / 16}vw !important`,
        top: `calc(50% - ${24 * scaleFactor / 16}vw)`,
      },
      '& .MuiInputBase-root .MuiAutocomplete-endAdornment button:first-of-type': {
        marginRight: `${-2 * scaleFactor / 16}vw`,
      },
      '& .MuiInputBase-root .MuiAutocomplete-endAdornment button': {
        fontSize: `${24 * scaleFactor / 16}vw`,
        padding: `${8 * scaleFactor / 16}vw`,
        '& svg': {
          width: `${24 * scaleFactor / 16}vw`,
          height: `${24 * scaleFactor / 16}vw`,
        }
      },
    }
  })}>
    <Grid item xs={6} sx={(theme) => ({
      [theme.breakpoints.up("md")]: {
        '&.MuiGrid-item.MuiGrid-root': {
          paddingLeft: `${24 * scaleFactor / 16}vw`,
          paddingTop: `${8 * scaleFactor / 16}vw`,
        },
        '& h4.MuiTypography-h4': {
          marginTop: `${30 * scaleFactor / 16}vw`,
          fontSize: `${14 * scaleFactor / 16}vw`,
          lineHeight: `${22 * scaleFactor / 16}vw`,
        }
      }
    })}>
      <LabeledAsyncSelect async={() => apartmentApi.operationTypes()} field="operationType" formik={formik} label={t('operationType')} placeholder={t('chooseOperationType')} required />
      <LabeledAsyncSelect async={() => apartmentApi.propertyTypes()} field="propertyType" formik={formik} label={t('rentType')} placeholder={t('chooseRentType')} required />
      <AddressAutocomplete disabled={disabled} field="state" formik={formik} label={t('region')} placeholder={t('chooseRegion')} required />
      <AddressAutocomplete disabled={disabledCity} field="city" formik={formik} label={t('city')} placeholder={t('chooseCity')} required />
      <AddressAutocomplete disabled={disabledCityRegion} field="cityRegion" formik={formik} label={t('district')} placeholder={t('chooseDistrict')} />
    </Grid>
    <Grid item xs={6} sx={(theme) => ({
      [theme.breakpoints.up("md")]: {
        '&.MuiGrid-item.MuiGrid-root': {
          paddingLeft: `${24 * scaleFactor / 16}vw`,
          paddingTop: `${8 * scaleFactor / 16}vw`,
        }
      }
    })}>
      <AddressAutocomplete disabled={disabledCitySubregion} field="citySubregion" formik={formik} label={t('microDistrict')} placeholder={t('chooseMicroDisrict')} />
      <AddressAutocomplete disabled={disabledStreet} field="street" formik={formik} label={t('street')} placeholder={t('chooseStreet')} required />
      <LabeledInput disabled={disabled} field="houseNumber" formik={formik} label={t('houseNumber')} placeholder={t('typeHouseNumber')} />
      <AddressAutocomplete disabled={disabled} field="residentialComplex" formik={formik} label={t('complex')} placeholder={t('chooseComplex')} />
      <Grid container columnSpacing={3} className={'sew'} sx={(theme) => ({
        [theme.breakpoints.up("md")]: {
          width: `calc(100% + ${24 * scaleFactor / 16}vw)`,
          marginLeft: `${-24 * scaleFactor / 16}vw`,
        }
      })}>
        <Grid item xs={8} sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            '&.MuiGrid-item': {
              paddingLeft: `${24 * scaleFactor / 16}vw`,
            }
          }
        })}>
          <LabeledInput disabled={disabled} field="price" formik={formik} label={t('price')} placeholder={t('objectType')} required />
        </Grid>
        <Grid className={'444'} item xs={4} sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            // paddingLeft: `${24 * scaleFactor / 16}vw`,
            '&.MuiGrid-item': {
              paddingLeft: `${24 * scaleFactor / 16}vw`,
            },
            '.custom--currency .MuiSelect-filled': {
              padding: `${vw(2)} ${vw(0)} !important`
            },
          }
        })}>
          <LabeledSelect
              disabled={disabled}
              label={t('currency')}
              field="currency"
              formik={formik}
              options={[{ value: 0, name: "USD" }, { value: 1, name: "UAH" }]}
              placeholder={t('chooseCurrency')}
          />
        </Grid>
      </Grid>

    </Grid>
  </Grid>;
}

export function DetailInformationBlock({ formik }) {
  const scaleFactor = useResize();
  const { t } = useTranslation(["translation", "form"]);

  return <Grid
      className={'990'}
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={(theme) => ({
        [theme.breakpoints.up("md")]: {
          width: `calc(100% + ${24 * scaleFactor / 16}vw)`,
          marginLeft:`${-24 * scaleFactor / 16}vw`,
          marginTop: `${-8 * scaleFactor / 16}vw`,
          '& .MuiInputBase-formControl.MuiInputBase-root': {
            padding: `${12 * scaleFactor / 16}vw ${39 * scaleFactor / 16}vw ${12 * scaleFactor / 16}vw ${12 * scaleFactor / 16}vw`,
            fontSize: `${16 * scaleFactor / 16}vw`,
            lineHeight: `${23 * scaleFactor / 16}vw`,
            borderTopLeftRadius: `${4 * scaleFactor / 16}vw`,
            borderTopRightRadius: `${4 * scaleFactor / 16}vw`
          },
          '& .MuiInputBase-root input': {
            padding: `${4 * scaleFactor / 16}vw 0 ${5 * scaleFactor / 16}vw !important`,
            fontSize: `${16 * scaleFactor / 16}vw`,
            lineHeight: `${23 * scaleFactor / 16}vw`,
            minWidth: `${30 * scaleFactor / 16}vw !important`,
            height: `${22 * scaleFactor / 16}vw !important`,
          },
        }
      })}>
    <Grid item xs={6} className={'070'} sx={(theme) => ({
      [theme.breakpoints.up("md")]: {
        '&.MuiGrid-item.MuiGrid-root': {
          paddingLeft: `${24 * scaleFactor / 16}vw`,
          paddingTop: `${8 * scaleFactor / 16}vw`,
        },
        '& h4.MuiTypography-h4': {
          marginTop: `${30 * scaleFactor / 16}vw`,
          fontSize: `${14 * scaleFactor / 16}vw`,
          lineHeight: `${22 * scaleFactor / 16}vw`,
        }
      }
    })}>
      <LabeledInput field="rooms" formik={formik} label={t('roomCount')} placeholder={t('typeRoomCount')} required />
      <LabeledInput endAdornment="м&#178;" field="totalArea" formik={formik} label={t('space')} placeholder={t('typeSpace')} required />
      <LabeledInput endAdornment="м&#178;" field="livingArea" formik={formik} label={t('liveSpace')} placeholder={t('typeLiveSpace')} />
      <LabeledInput endAdornment="м&#178;" field="kitchenArea" formik={formik} label={t('kitchenSpace')} placeholder={t('chooseKitchenSpace')} />
      <LabeledInput field="floor" formik={formik} label={t('floor')} placeholder={t('typeFloor')} required />
      <LabeledInput field="floorTotal" formik={formik} label={t('houseFloor')} placeholder={t('typeHouseFloor')} required />
      <LabeledAsyncSelect async={() => apartmentApi.repairTypes()} field="repair" formik={formik} label={t('repair')} placeholder={t('typeRepair')} required />
    </Grid>
    <Grid item xs={6} sx={(theme) => ({
      [theme.breakpoints.up("md")]: {
        '&.MuiGrid-item.MuiGrid-root': {
          paddingLeft: `${24 * scaleFactor / 16}vw`,
          paddingTop: `${8 * scaleFactor / 16}vw`,
        },
        '& h4.MuiTypography-h4': {
          marginTop: `${30 * scaleFactor / 16}vw`,
          fontSize: `${14 * scaleFactor / 16}vw`,
          lineHeight: `${22 * scaleFactor / 16}vw`,
        }
      }
    })}>
      <LabeledAsyncSelect className={'9990'} async={() => apartmentApi.ownerTypes()} field="owner" formik={formik} label={t('owner')} placeholder={t('typeOwner')} required />
      <LabeledInput className={'9991'} field="ownerName" formik={formik} label={t('ownerName')} placeholder={t('typeOwnerName')} />
      <InputPhoneMask className={'9992'} field="phone" formik={formik} label={t('typeOwnerContact')} />
      <LabeledInput className={'9993'} field="description" formik={formik} label={t('description')} multiline placeholder={t('objectDesctipyion')} rows={5} />
      <LabeledInput className={'9994'} field="serviceInformation" formik={formik} label={t('staffInfo')} multiline placeholder={t('typeStaffInfo')} rows={5} />
    </Grid>
  </Grid>;
}
