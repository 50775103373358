import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, MenuItem, Select, Typography } from "@mui/material";
import * as React from "react";

import { useTheme } from "@mui/material/styles";
import useResize from "../../config/themes/responsive";

export function MultiSelect({ handleChange, onDelete, options, title, value }) {
  const scaleFactor = useResize();
  const theme = useTheme();
  const vw = (px) => `${(px * scaleFactor) / 16}vw`;


    const menuProps = {
    PaperProps: {
      sx: {
        maxHeight: 200,
        [theme.breakpoints.up("md")]: {
          maxHeight: `${(200 * scaleFactor) / 16}vw`,
        },
      },
    },
  };

  return (
      <>
        <Typography
            variant="h4"
            sx={(theme) => ({
                mb: 0.75,
                mt: { xs: 3, [theme.breakpoints.up("md")]: `${(60 * scaleFactor) / 16}vw` },
                [theme.breakpoints.up("md")]: {
                    marginBottom: vw(6),
                    lineHeight: vw(22),
                    fontSize: vw(14),
                    marginTop: vw(24),
                },
            })}
        >
          {title}
        </Typography>
        <Select
            fullWidth
            multiple
            name="agents"
            onChange={handleChange}
            sx={(theme) => ({
                mb: { xs: 4.4, [theme.breakpoints.up("md")]: `${(88 * scaleFactor) / 16}vw` },
                [theme.breakpoints.up("md")]: {
                    marginBottom: vw(35),
                    borderTopLeftRadius: vw(4),
                    borderTopRightRadius: vw(4),
                    lineHeight: vw(23),
                    fontSize: vw(16),
                    '& .MuiSelect-multiple': {
                        lineHeight: vw(22),
                        fontSize: vw(14),
                        padding: `${vw(12)} ${vw(32)} ${vw(12)} ${vw(12)}`,
                        minHeight: vw(24),
                    },
                    '& .MuiSelect-iconFilled': {
                        width: vw(24),
                        height: vw(24),
                        fontSize: vw(24),
                        right: `${7 * scaleFactor / 16}vw`,
                        top: `calc(50% - ${12 * scaleFactor / 16}vw)`,
                    }
                },
            })}
            value={value}
            variant="filled"
            renderValue={(selected) => (
                <Box
                    sx={(theme) => ({
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5 ,
                    })}
                >
                  {selected.slice(0, 3).map((agent, key) => {
                    const label = `${agent.lastName} ${agent.firstName.charAt(0).toUpperCase()}. ${agent.middleName.charAt(0).toUpperCase()}.`;
                    return (
                        <Chip
                            key={agent.id}
                            label={label}
                            onDelete={() => onDelete(agent)}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            sx={{
                              backgroundColor: colors[key],
                              [theme.breakpoints.up("md")]: {
                                fontSize: `${(14 * scaleFactor) / 16}vw`,
                                height: `${(32 * scaleFactor) / 16}vw`,
                              },
                            }}
                            deleteIcon={<CloseIcon sx={{ [theme.breakpoints.up("md")]: { width: `${(11 * scaleFactor) / 16}vw`, height: `${(11 * scaleFactor) / 16}vw` } }} />}
                        />
                    );
                  })}
                  {selected.length > 3 && (
                      <Chip
                          key="total"
                          label={"+" + (selected.length - 3)}
                          sx={{
                            backgroundColor: colors.at(-1),
                            [theme.breakpoints.up("md")]: {
                              fontSize: `${(14 * scaleFactor) / 16}vw`,
                              height: `${(32 * scaleFactor) / 16}vw`,
                            },
                          }}
                      />
                  )}
                </Box>
            )}
            MenuProps={menuProps}
        >
          {options.map((agent) => (
              <MenuItem key={agent.id} value={agent}>
                {agent.lastName} {agent.firstName} {agent.middleName}
              </MenuItem>
          ))}
        </Select>
      </>
  );
}

const colors = [
  "rgba(28, 119, 255, 0.08)",
  "rgba(255, 138, 91, 0.08)",
  "rgba(130, 107, 255, 0.08)",
  "rgba(251, 195, 67, 0.08)",
];
