import { Autocomplete, CircularProgress, FormControl, TextField, Typography } from "@mui/material";
import { debounce } from '@mui/material/utils';
import { getTypeShortName, locationApi } from "helpers";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import useResize from "../../config/themes/responsive";

export function AddressAutocomplete({ disabled, field, formik, label, placeholder, required }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const scaleFactor = useResize();
  const value = formik.values[field];

  const vw = (px) => `${(px * scaleFactor) / 16}vw`;

  const fetch = useMemo(() => debounce((inputValue, field) => {
    const state = formik.values.state?.id
    const city = formik.values.city?.id
    const cityRegion = formik.values.cityRegion?.id
    const citySubregion = formik.values.citySubregion?.id
    const street = formik.values.street?.id
    const residentialComplex = formik.values.residentialComplex?.id

    let promise;

    switch (field) {
      case "street":
        promise = locationApi.streets({search: inputValue, state, city, cityRegion, citySubregion, residentialComplex});
        break;
      case "citySubregion":
        promise = locationApi.citySubregions({search: inputValue, state, city, cityRegion, street, residentialComplex});
        break;
      case "cityRegion":
        promise = locationApi.cityRegions({search: inputValue, state, city, citySubregion, street, residentialComplex});
        break;
      case "city":
        promise = locationApi.cities({search: inputValue, state, cityRegion, citySubregion, street, residentialComplex});
        break;
      case "residentialComplex":
        promise = locationApi.residentialComplexes({search: inputValue, state, cityRegion, citySubregion, street });
        break;
      case "state":
      default:
        promise = locationApi.states({state, city, cityRegion });
        break;
    }

    promise.then(({ result }) => {
      setLoading(false)
      setOptions(result)
    });
  }, 400,), [formik],)


  const handleChangeValuePreview = (event, newValue) => {

    if (event) {

      if (JSON.parse(localStorage.getItem('currentPreview')) !== null) {

        const apartmentsList = JSON.parse(localStorage.getItem('currentPreview'));

        const currentApartment = apartmentsList.map(apartment => {
          if (apartment.id === formik.values.id) {
            return {...apartment, [field]: newValue}
          }
          return apartment;
        })

        localStorage.setItem('currentPreview', JSON.stringify(currentApartment))
      }
    }
  }

  useEffect(() => {
    if (!open || (value && inputValue === getTypeShortName(value.type) + value.name)) {
      return undefined;
    }

    setLoading(true);
    fetch(inputValue, field);

  }, [open, field, inputValue, value, fetch]);

  const handleChange = (event, newValue) => {

    formik.setFieldValue(field, newValue, true)
    const mapAddress = ['state', 'city', 'cityRegion', 'citySubregion', 'street'];
    if (newValue === null || (formik.values.field !== null && newValue !== null)) {
      const index = mapAddress.indexOf(field);
      let result = [];
      if (index !== -1) {
        result = [...mapAddress.slice(index + 1)];
      }
      if (result.length) {
        for(const item of result) {
          formik.setFieldValue(item, null, true)
        }
      }
    }
    formik.setFieldValue(field, newValue, true)

    handleChangeValuePreview(event, newValue)
  }

  return (
      <FormControl fullWidth>
        <Typography
            className={required && "label"}
            variant="h4"
            sx={(theme) => ({
              mt: 1.5,
              [theme.breakpoints.up("md")]: {
                  marginTop: vw(30),
                  fontSize: vw(14),
                  lineHeight: vw(22),
              },
            })}
        >
          {label}
        </Typography>

        <Autocomplete
            value={formik.values[field]}
            className={'090'}
            fullWidth
            open={open}
            onChange={(event, newValue) => handleChange(event, newValue)}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            getOptionLabel={(option) => getTypeShortName(option.type) + option.name}
            options={options}
            loading={loading}
            isOptionEqualToValue={(option, val) => option.id === val.id}
            onInputChange={(e, val) => setInputValue(val)}
            sx={(theme) => ({
              mb: 0.5,
              mt: 1,
              [theme.breakpoints.up("md")]: {
                marginTop: vw(8),
                marginBottom: vw(4),
                '& span.MuiCircularProgress-root': {
                  width: vw(20) + ' !important',
                  height: vw(20) + ' !important',
                },
                '& .MuiAutocomplete-endAdornment': {
                  right: vw(8),
                },
                '& .MuiAutocomplete-inputRoot': {
                  padding: vw(4),
                },
              },
            })}
            renderInput={(params) => (
                <TextField
                    {...params}
                    className={'020'}
                    variant="filled"
                    error={formik.errors[field] && formik.touched[field]}
                    helperText={formik.touched[field] && formik.errors[field]}
                    placeholder={placeholder}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        padding: 0,
                        height: vw(22),
                      },
                    }}
                    sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            '& .MuiFilledInput-root': {
                                fontSize: vw(16),
                                lineHeight: vw(23),
                            },
                            '& .MuiFilledInput-input': {
                                padding: vw(12),
                                fontSize: vw(14),
                                lineHeight: vw(22),
                                height: vw(22),
                            },
                            '&.field-label-tab2 .MuiInputBase-root .MuiInputAdornment-root': {
                                marginLeft: vw(8),
                            },
                            '&.field-label-tab2 .MuiInputBase-root .MuiInputAdornment-root p': {
                                fontSize: vw(16),
                                lineHeight: vw(24)
                            },
                            '& .MuiAutocomplete-loading': {
                                fontSize: vw(14),
                                padding: `${vw(8)} ${vw(12)}`,
                            },
                            '& p.MuiFormHelperText-root.Mui-error': {
                                fontSize: vw(14),
                                marginLeft: vw(14),
                                marginRight: vw(14),
                                marginTop: vw(3),
                                lineHeight: vw(26),
                            }
                        },
                    })}
                    sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            marginTop:  vw(8),
                            marginBottom: vw(4),
                            '& .MuiInputBase-root': {
                                borderTopLeftRadius: vw(4),
                                borderTopRightRadius: vw(4),
                                fontSize: vw(16),
                                lineHeight:  vw(23),
                                padding: `${12 * scaleFactor / 16}vw ${39 * scaleFactor / 16}vw ${12 * scaleFactor / 16}vw ${12 * scaleFactor / 16}vw`,
                                '&:after, &:before': {
                                    borderBottom: `${1 * scaleFactor / 16}vw solid rgba(255, 255, 255, 0.7)`
                                },
                                '& .MuiInputBase-input': {
                                    padding:  vw(12),
                                    fontSize: vw(14),
                                    lineHeight: vw(22),
                                    height: vw(22),
                                },
                                '& .MuiAutocomplete-endAdornment': {
                                    right: vw(9),
                                    top: `calc(50% - ${20 * scaleFactor / 16}vw)`,
                                },
                                '& .MuiAutocomplete-endAdornment button:first-of-type': {
                                    marginRight: vw(-2),
                                },
                                '& .MuiAutocomplete-endAdornment button': {
                                    fontSize: vw(24),
                                    padding: vw(8),
                                    '& svg': {
                                        width: vw(24),
                                        height: vw(24),
                                        fontSize: vw(24),
                                    }
                                },
                            }
                        }
                    })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                          <Fragment>
                            {loading ? (
                                <CircularProgress
                                    color="inherit"
                                    size={vw(20)}
                                    sx={{
                                      width: vw(20),
                                      height: vw(20),
                                    }}
                                />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                      ),
                    }}
                />
            )}
            renderOption={(props, option) => (
                <li
                    {...props}
                    style={{
                      padding: `${vw(8)} ${vw(12)}`,
                      fontSize: vw(14),
                    }}
                >
                  {getTypeShortName(option.type) + option.name}
                </li>
            )}
        />
      </FormControl>
  );
}
